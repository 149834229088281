import React from 'react';
import './Hero.css' ;
import Header from './Header/Header';
import hero_image from '../assets/hero_image.png' ;
import hero_image_back from '../assets/hero_image_back.png' ;
import Heart from '../assets/heart.png' ;
import calories from '../assets/calories.png' ;
import NumberCounter from 'number-counter' ;


const Hero = () => {
  return (
    <div className='hero' id='home'>
      <div className="blur blur-hero"></div>
      <div className="left-h">
        <Header/>

        
        {/* tagline */}


        <div className="the-best-ad">
          <div></div>
          <span>The Best Fitness In the Town</span>
        </div>

        {/* Hero heading */}

        <div className="hero-text">
          <div>
            <span className='stroke-text'>Shape </span>
            <span>Yours</span>
          </div>
          <div>
            <span>Ideal Body</span>
          </div>
          <div>
            <span>In here we Will help you to shape and build your ideal body and live up your life to fullest</span>
          </div>
        </div>

        {/* figures */}
        <div className="hero-figures">
          <div>
            <span>
              <NumberCounter end={140} start={100} delay = '4' preFix = "+"/>
            </span>
            <span>expert coachs</span>
          </div>
          <div>
            <span><NumberCounter end={978} start={900} delay = '4' preFix = "+"/></span>
            <span>Members Joined</span>
          </div>
          <div>
            <span><NumberCounter end={50} start={0} delay = '4' preFix = "+"/></span>
            <span>Fitness Programs</span>
          </div>
        </div>


        {/* hero buttons */}

        <div className="hero-buttons">
          <button className='btn'>Get Started</button>
          <button className='btn'>learn More</button>
        </div>


      </div>
      <div className="right-h">
        <button className="btn">Join Now</button>

        <div className="heart-rate">
          <img src={Heart} alt="" />
          <span>Heart Rate</span>116 bpm<span></span>
        </div>

        {/* hero Images */}
        <img src={hero_image} alt="" className='hero_image' />
        <img src={hero_image_back} alt="" className='hero_image-back'/>

        {/* calories  */}
        <div className="calories">
          <img src={calories} alt="" />
          <span>Calories Burned</span>
          <span>220kCal</span>
        </div>
      </div>
    </div>
  )
}

export default Hero