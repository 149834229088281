import React, { useState } from "react";
import Logo from "../../assets/logo.png";
import Bars from "../../assets/bars.png";
import { Link } from "react-scroll";
import "./Header.css";

const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpened, setMenuOpened] = useState(false);

  return (
    <div className="header">
      <img src={Logo} alt="" className="logo" />
      {menuOpened === false && mobile === true ? (
        <div>
          <img src={Bars} alt="" style={{width:'2.5rem'}} onClick={()=>{setMenuOpened(true)}}/>
        </div>
      ) : (
        <ul className="header-menu">
          <li>
            <Link onClick={()=>setMenuOpened(false)}
            activeClass="active"
            to="header"
            spy={true}
            smooth={true}>
            Home
            </Link>
            </li>
          <li>
            <Link onClick={()=>setMenuOpened(false)}
            to="programs"
            spy={true}
            smooth={true}>
            Programs
            </Link>
            </li>
          <li>
            <Link onClick={()=>setMenuOpened(false)}
            to="plans"
            spy={true}
            smooth={true}>
            Plans
            </Link>
            </li>
          <li>
            <Link onClick={()=>setMenuOpened(false)}
            to="Testimonials"
            spy={true}
            smooth={true}>
            Testimonials
            </Link>
            </li>
        </ul>
      )}
    </div>
  );
};

export default Header;
